"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import style from "./navbar.module.css";
import { usePathname, useRouter } from "next/navigation";
import { logout } from "@store/userLoginSlice";

export default function App() {
  const accessToken = useSelector((state) => state.userToken.value.token);
  const user = useSelector((state) => state.userToken.value.user);
  const url = usePathname();
  const [logoUrl, setLogoUrl] = useState("/");
  const CustomToggle = React.forwardRef(({ children, onClick, href }, ref) => (
    <Link
      href=""
      ref={ref}
      className="text-decoration-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const dispatch = useDispatch();
  const router = useRouter();
  const handleLogout = () => {
    dispatch(logout());
    router.push("/login");
  };

  // updating logo url on the base of accessToken and user
  useEffect(() => {
    if (accessToken && user && user.role) {
      setLogoUrl("/assessment");
    }
  }, [accessToken, user]);
  return (
    <Navbar expand="lg" className="">
      <Container>
        {/* {
          accessToken && user.role === "consumer" ? */}
        <Link href={logoUrl} className={`navbar-brand mt-2 d-flex ${style.largeLogo}`}>
          <div>
            <Image
              src="/logo.png"
              width={60}
              height={55}
              alt="Logo"
              className="d-block"
              priority
            />
          </div>
          {/* <Image
            src="/logo-text.png"
            width={265}
            height={75}
            alt="Picture of the author"
            className="d-none d-md-block"
            priority
          />
          <Image
            src="/logo-text.png"
            width={175}
            height={50}
            alt="Picture of the author"
            className="d-md-none d-block"
            priority
          /> */}
          <div>
            <h2>MIND BALANCE</h2>
            <p>
              “Your sanctuary for self-discovery and growth”
            </p>
          </div>
        </Link>
        {url === "/phone-verification" && (
          <Dropdown show={true} className={style.dropdownContainer}>
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-custom-components"
              className="p-2 mt-5 bg-success rounded-circle "
            >
              <Image
                src="/logo_on_branch.png"
                width={40}
                height={40}
                alt="Profile Image"
                className={`rounded-circle me-2 profile-image`}
                style={{ border: "1px solid #AAE4EA" }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu
              className={`border-0 shadow-sm ${style.dropdownMenu}`}
              style={{ top: "38px" }}
            >
              <Button
                variant="link"
                className={`dropdown-item text-dark ${style.dropdownItem}`}
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Container>
    </Navbar>
  );
}
